





































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import BaseTeam from '@improve/common-components/src/components/team/BaseTeam.vue';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import Team from '@improve/common-utils/src/model/Team';
import { TeamMemberAction } from '@improve/common-utils/src/types/TeamMemberAction';

@Component({
  name: 'EditVirtualTeam',
  components: {
    BaseTeam,
    BaseButton
  }
})
export default class EditVirtualTeam extends Vue {
  @Getter allTeamsById!: (id: string) => Team;

  @Action updateTeam!: ActionMethod;

  @Action processTeamSubscriptions!: ActionMethod;

  private team: Team | null = null;

  private teamId: string = this.$route.params.id;

  get baseTeam(): any {
    return this.$refs.baseTeam as any;
  }

  created(): void {
    this.setTeam();
  }

  setTeam(): void {
    this.team = this.allTeamsById(this.teamId);
  }

  async handleSubmit(): Promise<void> {
    if (!(await this.baseTeam.validate())) {
      return;
    }

    const payload: {
      team: Team;
      actions: Map<string, TeamMemberAction>;
    } = this.baseTeam.prepareTeam();

    const savedTeam: Team = await this.updateTeam(payload.team);
    await this.processTeamSubscriptions({
      teamId: savedTeam.id,
      memberActions: payload.actions
    });
    this.goToTeamDetails(savedTeam.id as string);
  }

  goToTeamDetails(id: string = this.teamId): void {
    this.$router.push({
      name: 'TeamDetails',
      params: { id }
    });
  }
}
